@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "poppins", "inter";
  font-weight: 400;
  user-select: none;
}

@font-face {
  font-family: "Jeko Light";
  src: url("./assets/fonts/fonnts.com-Jeko_Light_Italic.ttf") format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Jeko Medium Italic";
  src: url("./assets/fonts/fonnts.com-Jeko_Medium_Italic.ttf") format("woff2");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Jeko Medium";
  src: url("./assets/fonts/JekoDEMO-Medium.otf") format("woff2");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Jeko Extra Bold";
  src: url("./assets/fonts/JekoDEMO-ExtraBold.otf") format("woff2");
  font-weight: 400;
  font-display: swap;
  /* font-style: italic; */
}

.lightJecko {
  font-family: "Jeko Light", sans-serif;
  font-weight: 300;
}
.lightJec {
  font-family: "Jeko Light", sans-serif;
  font-weight: 400;
}
.midiumJeko {
  font-family: "Jeko Medium Italic", sans-serif;
}
.JekoMedium {
  font-family: "Jeko Medium", sans-serif;
}
.extraBoldJeko {
  font-family: "Jeko Extra Bold", sans-serif;
  font-weight: 400;
}

.productCauousel .control-dots .dot {
  width: 100% !important;
  height: 4px !important;
  border-radius: 21px !important;
  margin: 0 !important;
}
.productCauousel:hover .control-dots {
  display: flex !important;
}

.productCauousel .control-dots {
  display: none !important;
  align-items: center !important;
  justify-content: space-between !important;
  gap: 6px !important;
  padding: 12px 12px 0 12px !important;
}
.carousel .control-dots .dot {
  box-shadow: none !important;
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
  width: 100%;
}
