.loader-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1112;
  top: 0;
  left: 0;
}


.loader {
  width: 70px;
  aspect-ratio: 1;
  --_c: no-repeat radial-gradient(farthest-side, #27c3ab 92%, #0000);
  background: var(--_c) top, var(--_c) left, var(--_c) right, var(--_c) bottom;
  background-size: 12px 12px;
  animation: l7 1s infinite;
}
@keyframes l7 {
  to {
    transform: rotate(0.5turn);
  }
}
